import React from 'react';
import ContactUs from '../ContactUsComponent';
import Accordion from '../common/Accordian';
import { ApplicationString } from '../../Constants/applicationString';

const Contact: React.FC = () => {
  const contactUsFAQS = ApplicationString.accordionContactUs.accordionData;

  return (
    <div className="bg-[#F3F4F6] dark:bg-dark_bg_surface">
      <ContactUs />
      <Accordion
        darkTheme="dark:bg-dark_bg_secondary"
        accordionData={contactUsFAQS}
        title={ApplicationString.contactUs.faqTitle}
      />
    </div>
  );
};

export default Contact;
