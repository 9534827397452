import { Dispatch } from '@reduxjs/toolkit';
import { HttpStatusCode } from 'axios';
import { useEffect, useState } from 'react';
import { IErrorObject } from './../Interfaces/interfaces';

// this function will return true if value is empty,undefine,length zero, object null other wise false
export const isNullOrEmpty = (value: unknown): boolean => {
  if (value === null || value === undefined) {
    return true;
  }

  if (typeof value === 'string' && value.trim() === '') {
    return true;
  }

  if (Array.isArray(value) && value.length === 0) {
    return true;
  }

  if (typeof value === 'object' && Object.keys(value).length === 0) {
    return true;
  }

  return false;
};

export const isJson = (jsonstring: string): boolean => {
  try {
    JSON.parse(jsonstring);
    return true;
  } catch (error) {
    return false;
  }
};

export const getUserDetailsFromLocalStorage = (key: string): string | null => {
  const localInfo = localStorage.getItem(key);
  if (localInfo !== null) {
    return JSON.parse(localInfo);
  } else {
    return null;
  }
};
// export const getLocalStorageUserId = ():string | null => {
//   return localStorage.getItem("userID");
// };

// export const getLocalStorageUserRole = ():string | null => {
//   return localStorage.getItem("Role");
// };

export const removeLocalStorageAccountInfo = (): void => {
  // localStorage.removeItem("account");
  localStorage.removeItem('accessToken');
  localStorage.removeItem('Role');
  localStorage.removeItem('userID');
};

export const userRoleEnums = {
  MASTER: 'master',
  ADMIN: 'admin',
  USER: 'user',
  SUPER_ADMIN: 'superAdmin',
};

/**
 * Converts a sorting order string to a common sorting order string.
 *
 * @param {string} sortOrder - The sorting order string to be converted.
 * @return {string} The converted sorting order string. If the input is 'ascend', it returns 'asc'. If the input is 'descend', it returns 'desc'. Otherwise, it returns an empty string.
 */
export const commonSortingOrder = (sortOrder: string): string => {
  if (sortOrder === 'ascend') {
    sortOrder = 'asc';
    return sortOrder;
  } else if (sortOrder === 'descend') {
    sortOrder = 'desc';
    return sortOrder;
  } else {
    sortOrder = '';
    return sortOrder;
  }
};
/**
 * An array of page size options.
 */

export const PageSizeArray = [10, 50, 100];

/**
 * An object containing default pagination values.
 */
export const DefaultPaginationValues = {
  PAGE_SIZE: 10,
  PAGE: 1,
  TOTAL_PAGES: 1,
};
/**
 * Formats a given price as a USD currency string.
 *
 * @param {number} price - The price to be formatted.
 * @return {string} The formatted price as a USD currency string.
 */
export const UsdCurrency = (price: number): string => {
  const formattedPrice = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(price);
  return formattedPrice;
};
/**
 * Formats a given date into a string representation of the date in the format "Month Day, Year".
 *
 * @param {Date} date - The date to be formatted.
 * @return {string} The formatted date string.
 */
export const DateFormat = (date: Date): string => {
  const dateString = new Date(date).toLocaleString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
  return dateString;
};
/**
 * Formats a given list of items into an array of objects with 'value' and 'label' properties.
 *
 * @param {ListItems[]} list - The list of items to be formatted.
 * @return {Array<{ value: string; label: string }>} The formatted array of objects.
 */
// export const OptionValuesFormat = (
//   list: ListItems[]
// ): { value: string; label: string } => {
//   const options = list?.map((item) => ({
//     ...item,
//     value: item.key,
//     label: item.key,
//   }));
//   return options;
// };+
/**
+ * Returns a function that checks if a given date is before the current date.
+ *
+ * @param {Date} currentDate - The current date.
+ * @return {Function} A function that takes a date and returns true if the date is before the current date, false otherwise.
+ */
export const disablePrevDates = (currentDate: Date) => {
  return (date: Date) => {
    return date && date < currentDate;
  };
};
/**
 * Capitalizes the first letter of each word in a given string.
 *
 * @param {string} str - The input string to capitalize.
 * @return {string} The string with the first letter of each word capitalized.
 */
export const CapitalizeWords = (str: string): string => {
  return str.replace(/\b\w/g, (char) => char.toUpperCase());
};
/**
 * Debounces the given value by delaying the update of the debounced value.
 *
 * @param {string} value - The value to be debounced.
 * @param {number} delay - The delay in milliseconds before updating the debounced value.
 * @return {string} The debounced value.
 */
export const Debounce = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handle = setTimeout(() => {
      setDebouncedValue(value);
    });
    return () => {
      clearTimeout(handle);
    };
  }, [value, delay]);
  return debouncedValue;
};

/**
 * Handles errors from the API and dispatches an action to display the error message.
 *
 * @param error - The error object from the API response.
 * @param defaultErrorMessage - The default error message to display if the API response does not contain one.
 * @param dispatch - The Redux dispatch function.
 */
export const handleApiErrorFunction = (
  error: IErrorObject,
  dispatch: Dispatch,
  defaultErrorMessage: string
) => {
  // Extract error message from the API response, depending on the HTTP status code.
  let errorMessage: string = '';
  switch (error?.response?.status) {
    // Errors that may contain a custom error message from the API.
    case HttpStatusCode.InternalServerError:
    case HttpStatusCode.BadRequest:
    case HttpStatusCode.NotFound:
    case HttpStatusCode.Unauthorized:
    case HttpStatusCode.Forbidden:
      errorMessage = defaultErrorMessage || error?.response?.data?.errorMessage;
      break;
    // For other errors, use the default error message.
    default:
      errorMessage = defaultErrorMessage;
      break;
  }
  console.error(errorMessage);
  // Dispatch an action to display the error message.
  // dispatch({ type: FETCH_ERROR, payload: errorMessage });
};

export const emailPattern = '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$';
export const SpacePattern = '^.*\\S.*$';
export const commonRegexs = {
  email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  name: /^[a-zA-Z\s]+$/,
};

export const contactUsAttachment = [
  'image/png',
  'image/jpeg',
  'image/jpg',
  'application/pdf',
];
export const uploadMaxFileSize = 5; // in MB
