import React from 'react';
import NotAuthorized from '../Screen/ErrorPages/notAuthorize/notAuthorized';
import Notfound from '../Screen/ErrorPages/notFound/notFound';
import HomeScreen from '../Screen/Home';
import OwnerScreen from '../Screen/Owner';
import { InternalRoute } from '../Utils/internalRoutes';
import { userRoleEnums } from '../Utils/utils';
import PrivacyScreen from '../Screen/PrivacyPolicy';
import Contact from '../Components/Contact';
import TermsConditionScreen from '../Screen/TermsCondition';

type RouteConfigType = {
  path: string;
  element: React.ReactElement;
  isProtectedRoute: boolean;
  allowedLayout: string;
  allowedRoles: string[];
};

export const RouteConfig: RouteConfigType[] = [
  {
    path: InternalRoute.Home,
    element: <OwnerScreen />,
    isProtectedRoute: false,
    allowedLayout: 'main',
    allowedRoles: [userRoleEnums.USER],
  },
  {
    path: InternalRoute.Owner,
    element: <HomeScreen />,
    isProtectedRoute: false,
    allowedLayout: 'main',
    allowedRoles: [userRoleEnums.USER],
  },
  {
    path: InternalRoute.Privacy,
    element: <PrivacyScreen />,
    isProtectedRoute: false,
    allowedLayout: 'main',
    allowedRoles: [userRoleEnums.USER],
  },
  {
    path: InternalRoute.TermsAndConditions,
    element: <TermsConditionScreen />,
    isProtectedRoute: false,
    allowedLayout: 'main',
    allowedRoles: [userRoleEnums.USER],
  },
  {
    path: InternalRoute.Unauthorized,
    element: <NotAuthorized />,
    isProtectedRoute: false,
    allowedLayout: 'main',
    allowedRoles: [userRoleEnums.USER],
  },
  {
    path: '*',
    element: <Notfound />,
    isProtectedRoute: false,
    allowedLayout: '',
    allowedRoles: [userRoleEnums.USER],
  },
  {
    path: InternalRoute.Contact,
    element: <Contact />,
    isProtectedRoute: false,
    allowedLayout: 'main',
    allowedRoles: [userRoleEnums.USER],
  },
];
