import React, { ChangeEvent, useRef } from 'react';
import {
  // ContactSvgIcon,
  EmailSvgIcon,
  // LocationSvgIcon,
  UploadSvgIcon,
} from '../../Assets/Svgs';
import { ApplicationString } from '../../Constants/applicationString';
import contactUs1Img from '../../Assets/Images/contactus1Img.png';
import {
  commonRegexs,
  contactUsAttachment,
  uploadMaxFileSize,
} from '../../Utils/utils';
import uploadImage from '../../Assets/Images/image.png';
import LoadingButton from '../common/LoadingButton/LoadingButton';
import { AxiosError, HttpStatusCode } from 'axios';
import { URLS } from '../../Constants/urls';
import { ApiRequest } from '../../Api/api';
import { IApiErrorResponse } from '../../Interfaces/interfaces';
import { useToast } from '../../Services/ToastService';
import '../ContactUsComponent/contactUs.css';

interface IContactUsFormData {
  fullName: string;
  email: string;
  message: string;
  attachment: File | null;
}

interface IErrosArray {
  propertyName: string;
  message: string;
}

interface IErrorsData {
  fullName: {
    error: boolean;
    ValidationMessage: string;
  };
  email: {
    error: boolean;
    ValidationMessage: string;
  };
  message: {
    error: boolean;
    ValidationMessage: string;
  };
  attachment: {
    error: boolean;
    ValidationMessage: string;
  };
}

const ContactUs: React.FC = () => {
  const [formData, setFormData] = React.useState<IContactUsFormData>({
    fullName: '',
    email: '',
    message: '',
    attachment: null,
  });
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [errorsData, setErrorsData] = React.useState<IErrorsData>({
    fullName: {
      error: false,
      ValidationMessage: '',
    },
    email: {
      error: false,
      ValidationMessage: '',
    },
    message: {
      error: false,
      ValidationMessage: '',
    },
    attachment: {
      error: false,
      ValidationMessage: '',
    },
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [open, setOpen] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [isClicked, setIsClicked] = React.useState(false);

  const handleUploadClick = () => {
    if (!isClicked) {
      setIsClicked(true);
      setTimeout(() => setIsClicked(false), 500);
    }
  };

  const handleClick = () => {
    setOpen(true);
  };

  const toast = useToast();

  const clearForm = () => {
    setFormData((prevState) => ({
      ...prevState,
      fullName: '',
      email: '',
      message: '',
      attachment: null,
    }));

    setTimeout(() => {
      setErrorsData({
        fullName: { error: false, ValidationMessage: '' },
        email: { error: false, ValidationMessage: '' },
        message: { error: false, ValidationMessage: '' },
        attachment: { error: false, ValidationMessage: '' },
      });
    }, 5);

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleFullNameChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const fullName = e.target.value;
    const nameRegex = commonRegexs.name;
    let fullNameError = false;
    let validationMessage = '';

    if (!nameRegex.test(fullName) && formData.fullName !== '') {
      fullNameError = true;
      validationMessage =
        ApplicationString.contactUs.validationMessages.formatMsgs.fullName;
    }

    if (fullName.trim() === '') {
      fullNameError = false;
      validationMessage = '';
    }

    setFormData((prevState) => ({
      ...prevState,
      fullName: e.target.value,
    }));

    setErrorsData((prevState) => ({
      ...prevState,
      fullName: {
        error: fullNameError,
        ValidationMessage: validationMessage.trim(),
      },
    }));
  };

  const emailChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const email = e.target.value;
    const emailRegex = commonRegexs.email;

    let emailError = false;
    let validationMessage = '';

    if (!emailRegex.test(email) && formData.email !== '') {
      emailError = true;
      validationMessage =
        ApplicationString.contactUs.validationMessages.invalidMsgs.invalidEmail;
    }
    if (email.trim() === '') {
      emailError = false;
      validationMessage = '';
    }
    setFormData((prevState) => ({
      ...prevState,
      email: email.trim(),
    }));

    setErrorsData((prevState) => ({
      ...prevState,
      email: {
        error: emailError,
        ValidationMessage: validationMessage.trim(),
      },
    }));
  };
  const messageChangeHandler = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData((prevState) => ({
      ...prevState,
      message: e.target.value,
    }));
    setErrorsData({
      ...errorsData,
      message: { error: false, ValidationMessage: '' },
    });
  };

  const handleAttachmentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let attachmentError = false;
    let validationMessage = '';
    const file = e.target.files && e.target.files[0];
    if (file) {
      const isRightFormat = contactUsAttachment.includes(file.type);

      if (!isRightFormat) {
        attachmentError = true;
        validationMessage =
          ApplicationString.contactUs.validationMessages.fileMsgs.format;
      }

      const fileSizeInMB = file.size / (1024 * 1024);
      if (fileSizeInMB > uploadMaxFileSize) {
        attachmentError = true;
        validationMessage =
          ApplicationString.contactUs.validationMessages.fileMsgs.size;
      }
    }
    setErrorsData({
      ...errorsData,
      attachment: {
        error: attachmentError,
        ValidationMessage: validationMessage,
      },
    });
    if (!attachmentError) {
      setFormData((prevState) => ({
        ...prevState,
        attachment: file,
      }));
    }
  };

  const showValidationErrors = (err: AxiosError<IApiErrorResponse>) => {
    if (err?.response?.data?.errors && err.response.data.errors.length > 0) {
      const errorDataArray = err.response.data.errors as IErrosArray[];
      errorDataArray.forEach((error) => {
        if (error.propertyName === 'fullName') {
          setErrorsData((prevState) => ({
            ...prevState,
            fullName: {
              error: true,
              ValidationMessage: error.message,
            },
          }));
        }
        if (error.propertyName === 'email') {
          setErrorsData((prevState) => ({
            ...prevState,
            email: {
              error: true,
              ValidationMessage: error.message,
            },
          }));
        }
      });
    } else {
      if (
        err?.response?.status === HttpStatusCode.NotFound &&
        formData.email.trim()
      ) {
        setErrorsData((prevState) => ({
          ...prevState,
          email: {
            error: true,
            ValidationMessage: err?.response ? err?.response.data.message : '',
          },
        }));
      }
      if (
        err?.response?.status === HttpStatusCode.BadRequest &&
        formData.fullName.trim()
      ) {
        setErrorsData((prevState) => ({
          ...prevState,
          fullName: {
            error: true,
            ValidationMessage: err?.response ? err?.response.data.message : '',
          },
        }));
      }
    }
  };

  const handlePostContactUs = async () => {
    const payloadFormData = new FormData();
    if (
      !formData.fullName.trim() ||
      !formData.email.trim() ||
      errorsData.email.error ||
      errorsData.fullName.error ||
      errorsData.attachment.error
    ) {
      return;
    }
    if (formData.attachment) {
      payloadFormData.append('file', formData.attachment);
    }

    payloadFormData.append('name', formData.fullName.trim());
    payloadFormData.append('email', formData.email.trim());
    payloadFormData.append('message', formData.message.trim());

    try {
      setIsSubmitting(true);
      const res = await ApiRequest.post(URLS.CONTACT_US, payloadFormData);
      if (res?.status === HttpStatusCode.Created) {
        handleClick();
        clearForm();
        toast.success(ApplicationString.contactUs.successMessage);
      }
    } catch (error) {
      const axiosError = error as AxiosError<IApiErrorResponse>;
      showValidationErrors(axiosError);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrorsData((prevState) => ({
      ...prevState,
      fullName: {
        error: !formData.fullName.trim(),
        ValidationMessage: !formData.fullName.trim()
          ? ApplicationString.contactUs.validationMessages.requiredMsgs.fullName
          : '',
      },
      email: {
        error: !formData.email.trim(),
        ValidationMessage: !formData.email.trim()
          ? ApplicationString.contactUs.validationMessages.requiredMsgs.email
          : '',
      },
    }));
    const email = formData.email.trim();
    const emailRegex = commonRegexs.email;

    let emailError = false;
    let validationMessage = '';

    if (!emailRegex.test(email) && formData.email !== '') {
      emailError = true;
      validationMessage =
        ApplicationString.contactUs.validationMessages.invalidMsgs.invalidEmail;
      setErrorsData((prevState) => ({
        ...prevState,
        email: {
          error: emailError,
          ValidationMessage: validationMessage.trim(),
        },
      }));
    }
    try {
      await handlePostContactUs();
    } catch (error) {
      console.error('Contactus failed:', error);
    }
  };

  const deleteImage = () => {
    setFormData((prevState) => ({
      ...prevState,
      attachment: null,
    }));
    setErrorsData((prevState) => ({
      ...prevState,
      attachment: {
        error: false,
        ValidationMessage: '',
      },
    }));
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const deleteUploadAttachment = (
    <img
      className="w-[20px] MuiBox-root css-0"
      onClick={deleteImage}
      alt=""
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAOxAAADsQBlSsOGwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAJISURBVEiJtZVNaxRBEIaf6sksSTDMgkTDRoP4ET9yEv+DF0FRSFZvCoIH9aTgRTDeTPTqQTDkrB4SEPHif4gHQXCWKIFEV5BRV7JudqbLw+yu7sfMTpD0qaa6qp56q5se2OElWYL2XFrd64Z2SsUaAFFjw5B35aXDX/8bUCj6p1GWgaGOrSro2Y3nk2/S8gf6AVBugJQFvYOgDZ8oMgfcBFIBLQXj59/vVtc5CWYX2MlWfeWaYIaQ6FU72Dmj2KoIT1ouS0kMP6UerawvHf/WpkBzA49RiqD/chGJWwZztb01ReK4uZbLtGo9Ay62AWQrvK6u8xRkFtVjCPON8GFgPGEC6yCbjQq3UEqI3pOtaCUhHgrF0mJhxi8nBiTm+Z8KxdJip990OlQ1APLN77Fpf3Rs2h9Ns+NE8qL6vS/AoAGQO3D54yCAERaMsJBmM6sGGFHoAnRdU1UTIMpmTT3gtyojf/d62xMf1rwQjGZRoEYDANeq17mXtGr1aj5WZTKMyMZdRJAZ4DhOHkAliwLHBABGo8wAsZIHsLb7DHrcIhsA6DYUWI1vnWNsf0Bo3QBAkMwAg/UAbN3tD/B+hQ3ANhSYeERDToYzKL0+UgOqzRGJUBGhkmrHI4pWpw5WOuslPddBU4HUwysyYDXVBk/hB7NiswI+A/sBms9umo3KBKJfehVKArxUuFuYKd0G+zYhprHklKLngEeZAcMVHmyOcAL0Ycbf9rLRwfs98WlZ4xf8feTMUbVRzzgxjmqEv/Hi0FqWLnZk/QGX8Pmr++9W9gAAAABJRU5ErkJggg=="
    ></img>
  );

  return (
    <>
      <div className="w-full dark:border-dark_borderStroke dark:bg-dark_bg_primary">
        <div className="flex flex-col md:flex-row gap-[35px] mx-auto max-w-7xl px-6 pt-1 lg:px-8 2xl:px-0 lg:pt-5 ">
          <div className="w-full max-w-full md:w-1/2 md:max-w-[692px] mt-4">
            <div className="bg-white p-[24px] rounded-[20px] h-full dark:bg-dark_bg_secondary ">
              <div className="mb-3 font-bold text-xl sm:text-2xl 2xl:text-3xl text-left w-full dark:text-dark_primary_text">
                {ApplicationString.contactUs.lable}
              </div>
              <div className="text-[#6B7280] mb-[32px] dark:text-dark_secondary_text">
                <p>
                  {ApplicationString.contactUs.details.p1}
                  {ApplicationString.contactUs.details.p2}
                  {ApplicationString.contactUs.details.p3}
                </p>
              </div>
              <hr className="border-[1px] border-[#D1D5DB] dark:border-[#515151] mb-[32px]" />
              <form onSubmit={handleSubmit}>
                <div className="flex flex-col sm:flex-row w-full gap-[12px] mb-[12px]">
                  <div className="flex flex-col w-full gap-[12px] mb-[12px]">
                    <div className="flex flex-col sm:flex-row w-full gap-[12px] sm:-mb-[3px]">
                      <div className={`formLabel flex flex-col w-full`}>
                        <input
                          id="fullName"
                          className={`formInput text-base border w-full rounded-full p-[22px] py-3 h-[56px] border-[#d1d5db] bg-[#f7fbff] text-[#000000]  focus:outline-none ${errorsData?.fullName?.error ? 'border-[#f30c0c] hover:border-[#d1d5db]' : ''}`}
                          type="text"
                          placeholder={''}
                          value={formData.fullName}
                          name="fullname-input"
                          data-testid="fullname-input"
                          onChange={handleFullNameChange}
                        />
                        <label
                          htmlFor="fullName"
                          className={`labelSpan ${errorsData?.fullName?.error ? 'important-top text-[#f30c0c]' : errorsData?.email?.error ? 'after-error-validation' : ''}`}
                        >
                          {ApplicationString.contactUs.fields.nameLable}
                        </label>

                        <p className="text-red-500 font-normal text-[12px] text-left mt-[3px] mr-[14px] pl-[10px]">
                          {errorsData?.fullName?.ValidationMessage}
                        </p>
                      </div>
                      <div className={`formLabel flex flex-col w-full`}>
                        <input
                          className={`formInput text-base border w-full rounded-full p-[22px] py-3 h-[56px] border-[#d1d5db] bg-[#f7fbff] text-[#000000]  focus:outline-none ${errorsData?.email?.error ? 'border-[#f30c0c] hover:border-[#d1d5db]' : ''}`}
                          type="email"
                          placeholder={''}
                          value={formData.email}
                          name="email-input"
                          data-testid="email-input"
                          onChange={emailChangeHandler}
                        />
                        <label
                          className={`labelSpan ${errorsData?.email?.error ? 'important-top text-[#f30c0c]' : errorsData?.fullName?.error ? 'after-error-validation' : ''}`}
                        >
                          {ApplicationString.contactUs.fields.emailLable}
                        </label>
                        {errorsData?.email?.error ? (
                          <p className="text-red-500 font-normal text-[12px] text-left mt-[3px] mr-[14px] pl-[10px]">
                            {errorsData?.email?.ValidationMessage}
                          </p>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                    <div className="formLabel flex flex-col w-full h-[147px]">
                      <textarea
                        className="formInput text-base border w-full h-full rounded-[25px] p-[22px] py-3 border-[#d1d5db] bg-[#f7fbff] text-[#000000] resize-none"
                        placeholder={''}
                        value={formData.message}
                        name="message-input"
                        data-testid="message-input"
                        onChange={messageChangeHandler}
                      />
                      <label className="messageLabel">
                        {ApplicationString.contactUs.fields.messageLable}
                      </label>
                      {errorsData?.message?.error ? (
                        <p className="text-red-500 font-normal text-[12px] text-left mt-[3px] mr-[14px] pl-[10px]">
                          {errorsData?.message?.ValidationMessage}
                        </p>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="mb-6">
                      <label
                        htmlFor="attachment"
                        className={`text-[14px] relative flex items-center px-4 pl-[21px] border p-[22px] gap-2 rounded-full h-[56px] cursor-pointer border-[#d1d5db] bg-[#f7fbff] text-[#374151] hover:border-[#292929] hover:bg-[#1a56db0a]
                          before:absolute before:top-0 before:rounded-full before:left-0 before:h-full before:w-0 before:bg-black/5 before:transition-[width] before:duration-500 before:ease-linear
                          ${isClicked ? 'before:w-full before:opacity-0' : ''}
                        `}
                        onClick={handleUploadClick}
                      >
                        <span>
                          <UploadSvgIcon />
                        </span>{' '}
                        {ApplicationString.contactUs.fields.uploadAttachment}
                      </label>
                      <div className="px-2 py-1 text-sm text-secondary_text">
                        <p className="text-[#6b7280]">
                          {ApplicationString.contactUs.supportedFileMsg}
                        </p>
                      </div>
                      <input
                        id="attachment"
                        type="file"
                        ref={fileInputRef}
                        className="hidden"
                        placeholder=""
                        name="attachment-input"
                        data-testid="attachment-input"
                        onChange={handleAttachmentChange}
                        title={ApplicationString.contactUs.fields.messageTitle}
                        accept=".pdf, .jpeg, .jpg, .png"
                      />
                      {errorsData?.attachment?.error ? (
                        <p className="text-red-500 font-normal text-[12px] text-left mt-[3px] mr-[14px] pl-[10px]">
                          {errorsData?.attachment?.ValidationMessage}
                        </p>
                      ) : (
                        ''
                      )}
                      {formData.attachment?.name && (
                        <div className="flex items-center gap-2 m-2 justify-between">
                          <div className="flex items-center gap-2">
                            <img
                              className="w-[20px]"
                              src={uploadImage}
                              alt=""
                            />
                            <span className="text-sm text-[#374151]">
                              {formData.attachment?.name}
                            </span>
                          </div>

                          <div className="flex cursor-pointer">
                            {deleteUploadAttachment}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="flex flex-col sm:flex-row gap-[10px] sm:gap-[24px]">
                      <button
                        className="w-full sm:w-1/2 h-[47px] capitalize border-[1px] border-[#292929] dark:border-[#FAFAFA] rounded-[50px] text-[16px] font-bold text-[#292929] dark:text-[#FAFAFA] bg-white dark:bg-dark_bg_secondary hover:border-[#1a56db] hover:bg-[#1a56db0a]"
                        onClick={clearForm}
                      >
                        {ApplicationString.contactUs.buttons.clear}
                      </button>

                      {isSubmitting ? (
                        <LoadingButton
                          buttonText={
                            ApplicationString.contactUs.buttons.submitting
                          }
                          extraBtnClasses="w-full flex justify-center items-center rounded-3xl normal-case text-base font-bold py-3 cursor-not-allowed bg-primary dark:bg-darkPrimary text-white"
                        />
                      ) : (
                        <button
                          className="w-full h-[47px] sm:w-1/2 rounded-[50px] text-[16px] font-bold capitalize bg-[#1a56db] text-white hover:bg-[#123c99] hover:shadow-lg"
                          type="submit"
                        >
                          {ApplicationString.contactUs.buttons.submit}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="w-full max-w-full md:w-1/2 md:max-w-[692px] mt-4">
            <div className="flex flex-col gap-[16px] h-full">
              <div className="w-full rounded-xl">
                <img
                  className="w-full h-90 rounded-[20px] object-cover"
                  src={contactUs1Img}
                  alt=""
                />
              </div>
              <div className="w-full bg-white rounded-[20px] p-[16px]  dark:bg-dark_bg_secondary">
                <div className="flex gap-[12px] mb-[12px] border-[1px] rounded-[50px] px-[16px] py-[12px] border-[#E5E7EB] dark:border-[#ffffff3b]">
                  <div className="h-[40px] w-[40px]">
                    <EmailSvgIcon />
                  </div>
                  <div className="flex-grow">
                    <div className="text-[14px] md:text-[15px] lg:text-[18px] font-bold mt-[3px] mb-[7px] sm:mt-0 sm:mb-[4px] leading-none dark:text-[#4968AC] ">
                      {ApplicationString.contactUs.contactUsDetails.emailLable}
                    </div>
                    <div className="text-[14px] md:text-[15px] lg:text-[18px] font-normal text-[#6B7280] leading-none dark:text-dark_secondary_text">
                      {ApplicationString.contactUs.contactUsDetails.email}
                    </div>
                  </div>
                </div>
                {/* <div className="flex gap-[12px] mb-[12px] border-[1px] rounded-[50px] px-[16px] py-[12px] border-[#E5E7EB]  dark:border-[#ffffff3b]">
                  <div className="h-[40px] w-[40px]">
                    <ContactSvgIcon />
                  </div>
                  <div className="flex-grow">
                    <div className="text-[14px] md:text-[15px] lg:text-[18px] font-bold mt-[3px] mb-[7px] sm:mt-0 sm:mb-[4px] leading-none dark:text-[#4968AC]">
                      {ApplicationString.contactUs.contactUsDetails.phoneLable}
                    </div>
                    <div className="text-[14px] md:text-[15px] lg:text-[18px] font-normal text-[#6B7280] leading-none dark:text-dark_secondary_text">
                      {ApplicationString.contactUs.contactUsDetails.phone}
                    </div>
                  </div>
                </div>
                <div className="flex gap-[12px] mb-[12px] border-[1px] rounded-[50px] px-[16px] py-[12px] border-[#E5E7EB]   dark:border-[#ffffff3b]">
                  <div className="h-[40px] w-[40px]">
                    <LocationSvgIcon />
                  </div>
                  <div className="flex-grow">
                    <div className="text-[14px] md:text-[15px] lg:text-[18px] font-bold mt-[3px] mb-[7px] sm:mt-0 sm:mb-[4px] leading-none dark:text-[#4968AC] ">
                      {
                        ApplicationString.contactUs.contactUsDetails
                          .addressLable
                      }
                    </div>
                    <div className="text-[14px] md:text-[15px] lg:text-[18px] font-normal text-[#6B7280] leading-none dark:text-dark_secondary_text">
                      {ApplicationString.contactUs.contactUsDetails.address}
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
