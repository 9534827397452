import React from 'react';
import AppRouter from './Routes/appRouter';
import { Provider } from 'react-redux';
import store from './Store/store';
import ToastProvider from './Components/common/Toast/ToastProvider';

function App() {
  return (
    <React.Fragment>
      <Provider store={store}>
        <ToastProvider>
          <AppRouter />
        </ToastProvider>
      </Provider>
    </React.Fragment>
  );
}

export default App;
