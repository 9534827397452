import React from 'react';
import { commonConfig } from '../../../Constants/commonConfig';
import Logo from './../../../Assets/Images/logo-min.png';
import PlayStore from './../../../Assets/playStore.png';
import IosStore from './../../../Assets/iosStore.png';
import Instagram from './../../../Assets/Svgs/insta.png';
import Facebook from './../../../Assets/Svgs/fb.png';
import { ApplicationString } from '../../../Constants/applicationString';
import { Link } from 'react-router-dom';
import { InternalRoute } from '../../../Utils/internalRoutes';
const Footer: React.FC = () => {
  return (
    <React.Fragment>
      <footer className=" w-full p-4 bg-white shadow md:flex md:items-center ">
        <div className="mx-auto sm:flex max-w-7xl w-full justify-between px-2 py-4 xl:px-0 lg:px-8">
          <div className="flex sm:mb-0 mb-6 flex-col gap-4 sm:gap-4">
            <Link to={InternalRoute.Home}>
              <img
                className="h-10 sm:h-8 sm:w-fit cursor-pointer"
                src={Logo}
                alt=""
              />
            </Link>
            <div className="flex flex-col gap-2 sm:gap-2">
              <p className="text-black font-normal text-xl leading-7">
                {ApplicationString.footer.text.connectWithUs}
              </p>
              <div className="flex gap-2">
                <Link to="" className="text-primaryBlue hover:underline">
                  <img className="h-8 w-auto" src={Instagram} alt="" />
                </Link>
                <Link to="" className="text-primaryBlue hover:underline">
                  <img className="h-8 w-auto" src={Facebook} alt="" />
                </Link>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-4 sm:gap-2 ">
            <p className="text-black font-normal sm:text-end text-xl leading-7">
              {ApplicationString.footer.text.downloadOnlifeApp}
            </p>
            <div className="flex items-center gap-2">
              <img
                className="3xsm:h-9 2xsm:h-9.5 xsm:h-11.5 w-fit cursor-pointer"
                src={IosStore}
                alt=""
              />
              <img
                className="3xsm:h-9 2xsm:h-10 xsm:h-12 w-fit cursor-pointer"
                src={PlayStore}
                alt=""
              />
            </div>
          </div>
        </div>
      </footer>
      <footer className=" w-full p-4 bg-white border-t border-slate-300 shadow md:flex md:items-center md:justify-between  md:p-6 dark:bg-gray-800 dark:border-gray-600">
        <div className="text-primaryBlue mx-auto font-semibold sm:flex px-2 sm:px-0 max-w-7xl w-full justify-between lg:px-8 xl:px-0">
          <span className="text-sm text-gray-500 dark:text-gray-400">
            {commonConfig.CopyrightText}
          </span>
          <ul className="flex sm:text-center justify-start flex-wrap font-semibold items-center text-sm text-gray-500 dark:text-gray-400 sm:mt-0">
            <li>
              <Link to={InternalRoute.Contact} className="hover:underline me-4">
                {ApplicationString.footer.text.contactUs}
              </Link>
            </li>
            <li>
              <Link to={InternalRoute.Privacy} className="hover:underline me-4">
                {ApplicationString.footer.text.privacy}
              </Link>
            </li>
            <li>
              <Link
                to={InternalRoute.TermsAndConditions}
                className="hover:underline me-4"
              >
                {ApplicationString.footer.text.terms}
              </Link>
            </li>
          </ul>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
