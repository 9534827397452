import React from 'react';
import '../TermsCondition/termsCondition.css';
import { ApplicationString } from '../../../Constants/applicationString';

const TermsConditionComponent: React.FC = () => {
  return (
    <div className="w-full p-4 dark:bg-dark_bg_secondary bg-white">
      <div className="mx-auto sm:flex max-w-7xl w-full flex-col justify-between gap-3 px-2 py-4 2xl:px-0 lg:px-8">
        <div className="flex flex-col items-center justify-center">
          <h1 className="text-3xl text-black text-center font-semibold mb-2">
            {ApplicationString.termsAndConditions.title}
          </h1>
          <h2 className="text-xl font-medium text-center">
            {ApplicationString.termsAndConditions.updatedDate}
          </h2>
        </div>
        {ApplicationString.termsAndConditions.content && (
          <p
            className="my-5 sm:text-lg text-justify"
            dangerouslySetInnerHTML={{
              __html: ApplicationString.termsAndConditions.content,
            }}
          ></p>
        )}
        <div className="sm:text-lg mx-7 mobile-responsive">
          <ol className="list-decimal flex flex-col gap-3 marker:font-semibold">
            {ApplicationString?.termsAndConditions?.termsData?.map(
              (section) => (
                <>
                  <li>
                    <h2 className="text-2xl font-semibold">{section.title}</h2>
                    {section.content && (
                      <p className="mt-3 text-justify">{section.content}</p>
                    )}
                    {section.children && section.children.length > 0 && (
                      <div className="mt-3">
                        <ol className="list-none xsm:-ml-2">
                          {section.children.map((sub) => (
                            <li
                              className="list-nested-content mt-3"
                              key={sub.id}
                            >
                              <div className="flex flex-col">
                                <h3 className="text-xl font-semibold">
                                  {sub.subId} {sub.title}:
                                </h3>
                                {sub.content && (
                                  <p
                                    className="mt-2 text-justify"
                                    dangerouslySetInnerHTML={{
                                      __html: sub.content,
                                    }}
                                  ></p>
                                )}
                              </div>
                            </li>
                          ))}
                        </ol>
                      </div>
                    )}
                  </li>
                </>
              )
            )}
          </ol>
        </div>
        <div className="mt-3 font-semibold text-lg flex flex-col gap-2">
          <p>{ApplicationString.termsAndConditions.endContent1}</p>
          <p>{ApplicationString.termsAndConditions.endContent2}</p>
        </div>
      </div>
    </div>
  );
};

export default TermsConditionComponent;
