/* eslint-disable react/no-unescaped-entities */
import React from 'react';

const PrivacyComponent: React.FC = () => {
  return (
    <div className="w-full p-4 dark:bg-dark_bg_secondary bg-white">
      <div className="mx-auto sm:flex max-w-7xl w-full flex-col justify-between gap-3 px-2 py-4 2xl:px-0 lg:px-8 text-justify">
        <div className="flex flex-col items-center justify-center">
          <h1 className="text-3xl text-black text-center font-semibold mb-2">
            Onelife Rental, LLC Privacy Policy
          </h1>
          <h2 className="text-xl font-medium text-center">
            Last Rev: March 5, 2025
          </h2>
        </div>
        <p className="my-5 sm:text-lg">
          Onelife Rentals LLC ("we", "our", or "us") is committed to protecting
          your privacy. This Privacy Policy describes how we collect, use, and
          share personal information when you use our mobile application (the
          "App") within the United States. It also outlines your rights
          regarding your personal information under U.S. law, including the{' '}
          <span className="font-semibold">
            California Consumer Privacy Act (CCPA)
          </span>
          .
        </p>
        <div className="sm:text-lg mx-7">
          <ol className="list-decimal flex flex-col gap-3 marker:font-semibold">
            <li>
              {/* <span className="text-xl font-semibold">Information We Collect</span> */}
              <div className="mb-3">
                <h1 className="text-xl font-semibold">Data Collection</h1>
                <p className="mt-3">
                  Onelife collects or receives information as part of our
                  operations and providing our services, including when you
                  install, access, or use our services. In certain instances, we
                  may seek your consent to process your personal information for
                  a specific purpose related to our services, which will be
                  clearly communicated to you. You have the right to withdraw
                  your personal information at any time, at which point we will
                  cease processing your data and cancel your services with
                  Onelife.
                </p>
                <div className="mt-3">
                  <ul className="list-disc sm:flex flex-col gap-3 flex xsm:ml-5 lg:ml-10 mt-2">
                    <li>
                      <p className="font-semibold">
                        Public Information:{' '}
                        <span className="font-normal">
                          Your public listing(s) will display your information,
                          such as your user ID, name associated with your
                          account, public profile photo, city and state where
                          your posted Recreational Vehicle or Watercraft is
                          located, your listing description, your calendar
                          availability, and public reviews left by fellow users.
                          Our services allow your public profile and public
                          listing pages to be included in search engines and may
                          accordingly be published as search results with search
                          engines.
                        </span>
                      </p>
                    </li>
                    <li>
                      <p className="font-semibold">
                        Account Registration:{' '}
                        <span className="font-normal">
                          To create an account, you must provide some personal
                          data, including your name, email, address, age, and
                          your mobile phone number.
                        </span>
                      </p>
                    </li>
                    <li>
                      <p className="font-semibold">
                        Profile Information:{' '}
                        <span className="font-normal">
                          We may ask you to provide additional profile
                          information to use certain features of our services
                          which may include date of birth, street addresses,
                          driver’s license number, date of issuance and issuing
                          country and/or state, profile photos, and/or
                          individual biography.
                        </span>
                      </p>
                    </li>
                    <li>
                      <p className="font-semibold">
                        Rental Recreational Vehicle or Watercraft Information:{' '}
                        <span className="font-normal">
                          We collect information that you provide in relation to
                          your listed Recreational Vehicle or Watercraft such as
                          vehicle identification number (VIN), vehicle location,
                          availability dates, reviews, and uploaded trip photos.
                          <br />
                          <br />
                          We may also collect additional information from you
                          such as driving history, criminal conviction history,
                          and other information related to our underwriting
                          process. We additionally require permission to check
                          your driver’s record in order to confirm you meet
                          Onelife’s eligibility standards (e.g., age). You
                          authorize us the access and release of your driver’s
                          record from state DMVs, RMVs, and other applicable
                          organizations.
                        </span>
                      </p>
                    </li>
                    <li>
                      <p className="font-semibold">
                        Payment Information:{' '}
                        <span className="font-normal">
                          We collect your digital payment data, such as bank
                          account number(s), payment card numbers, and
                          transaction information in connection with a potential
                          and/or actual transaction. This information may be
                          processed and stored by one or more third party
                          payment service providers.
                        </span>
                      </p>
                    </li>
                    <li>
                      <p className="font-semibold">
                        Device and Usage Data:{' '}
                        <span className="font-normal">
                          We collect information about your computer and/or
                          mobile device used to view our services, such as its
                          operating system type, version number, manufacturer
                          and model, browser type, screen resolution, and IP
                          address. We may also collect general location
                          information such as city, state, or geographic area
                          from your device. Interactions with our Services from
                          your device will additionally be collected, such as
                          pages viewed, information about your activity on a
                          page or screen, duration of access, history, searches,
                          bookings, and/or navigation paths.
                        </span>
                      </p>
                    </li>
                    <li>
                      <p className="font-semibold">
                        GPS Tracking:{' '}
                        <span className="font-normal">
                          If a Global Positioning System (GPS) tracking device
                          is installed and activated in a Recreational Vehicle
                          or Watercraft, Onelife may gather information about
                          your use of the Recreational Vehicle or Watercraft,
                          including time of use, location, speed of travel, etc.
                          Your location information may be released to insurance
                          companies, law enforcement, or similar parties when
                          investigating claims or when required by law.
                        </span>
                      </p>
                    </li>
                    <li>
                      <p className="font-semibold">
                        Background Checks:{' '}
                        <span className="font-normal">
                          To the extent permitted by applicable laws, Onelife
                          may collect background information about you from
                          public records, background check providers, or other
                          screening services. This may include credit reports,
                          criminal convictions, or sex offender registrations.
                          We may use your information, including your full name
                          and date of birth, to obtain such reports. Where we
                          are required by law to collect your personal
                          information, or where we need your personal
                          information to provide our services for you, if you do
                          not provide this information when requested (or the
                          information has been deleted at your request), we may
                          not be able to provide you with our services.
                        </span>
                      </p>
                    </li>
                    <li>
                      <p className="font-semibold">
                        Third Party Services:{' '}
                        <span className="font-normal">
                          We are not responsible for the privacy of information
                          or other practices performed by any third parties,
                          including any third party operating a site or service
                          to which the services are linked. The inclusion of a
                          link on a page related to our services does not imply
                          endorsement of the linked site or service by us or by
                          our affiliates. If you disclose your information to
                          others, or if you are directed to a third-party
                          website, their privacy notices and practices will
                          apply.
                        </span>
                      </p>
                    </li>
                    <li>
                      <p className="font-semibold">
                        Business Transfers:{' '}
                        <span className="font-normal">
                          In connection with a business transaction such as a
                          merger, acquisition, reorganization or sale of assets,
                          or in the event of bankruptcy, we may sell, transfer,
                          or otherwise share some of, or all, our business or
                          assets, including your personal information.
                        </span>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li>
              <div className="mb-3">
                <h1 className="text-xl font-semibold">How We Use Your Data</h1>
                {/* <p className="mt-3">
                  We may use the information we collect from you for the
                  following purposes:
                </p> */}
                <div className="mt-3">
                  <ul className="list-disc sm:flex flex-col gap-3 flex xsm:ml-5 lg:ml-10 mt-2">
                    <li>
                      <p className="font-semibold">
                        Digital Cookies:{' '}
                        <span className="font-normal">
                          When you access our services, we, and connected
                          third-party services, may place small data files on
                          your computer or other device. These data files may be
                          cookies, clear gifs, pixel tags, e-tags, “Flash
                          cookies”, or other local storage provided by your
                          browser or associated applications (collectively
                          “Cookies”). We use cookies to operate and support our
                          services, including operation of additional web-based
                          services, access to software that enhances your
                          experience, and to customize our services.
                        </span>
                      </p>
                    </li>
                    <li>
                      <p className="font-semibold">
                        Advertising:{' '}
                        <span className="font-normal">
                          We may provide customized interest-based ads both on
                          and off of our website. We target (and measure the
                          performance of) ads to Members and others both on and
                          off of our Services through a variety of ad networks
                          and exchanges, whether separately or combined.
                        </span>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li>
              <div className="mb-3">
                <h1 className="text-xl font-semibold">Data Subject Rights</h1>
                {/* <p className="mt-3">
                  We do not sell your personal information. However, we may
                  share your information with third parties under the following
                  circumstances:
                </p> */}
                <div className="mt-3">
                  <ul className="list-disc sm:flex flex-col gap-3 flex xsm:ml-5 lg:ml-10 mt-2">
                    <li>
                      <p className="font-semibold">
                        Targeted Ads:{' '}
                        <span className="font-normal">
                          You have the right to opt-out of receiving
                          interest-based advertising. This does not opt you out
                          of receiving general advertising—you will continue to
                          be presented generic ads or ads by advertisers not
                          listed with certain self-regulatory tools. To disable
                          targeted ads, please contact us.
                        </span>
                      </p>
                    </li>
                    <li>
                      <p className="font-semibold">
                        Minors:{' '}
                        <span className="font-normal">
                          Our services are not intended for anyone under the age
                          of 18. Onelife does not knowingly collect or solicit
                          personal information from anyone under the age of 18.
                        </span>
                      </p>
                    </li>
                    <li>
                      <p className="font-semibold">
                        California Privacy Rights.:{' '}
                        <span className="font-normal">
                          California law permits users who are California
                          residents to request and obtain from us once a year,
                          free of charge, a list of the third parties to whom we
                          have disclosed their personal information for their
                          direct marketing purposes in the prior calendar year,
                          as well as the type of personal information disclosed
                          to those parties. In your request, please attest to
                          the fact that you are a California resident and
                          provide a current California address for the response.
                        </span>
                      </p>
                    </li>
                    <li>
                      <p className="font-semibold">
                        Non-California US Residents:{' '}
                        <span className="font-normal">
                          If you reside in Colorado, Connecticut, Montana,
                          Oregon, Texas, Utah or Virginia, or are otherwise
                          protected by privacy and data protection laws, you may
                          contact us to exercise any data subject right that you
                          may have under applicable law. We may require you to
                          verify your identity and residency before we are able
                          to process your request.
                        </span>
                      </p>
                    </li>
                    <li>
                      <p className="font-semibold">
                        Security of Your Data:{' '}
                        <span className="font-normal">
                          While we employ a number of technical, physical, and
                          organizational measures designed to protect your data,
                          we cannot guarantee the security of your personal
                          information, as no method of transmitting or storing
                          information can be 100% secure.
                        </span>
                      </p>
                    </li>
                    <li>
                      <p className="font-semibold">
                        Changes to Privacy Policy:{' '}
                        <span className="font-normal">
                          We reserve the right to modify this Privacy Policy at
                          any point in time. See the “Last revised” date at the
                          top of this page to determine when this Privacy Policy
                          was last revised. Any changes to this Privacy Policy
                          will become effective when we post the revised Privacy
                          Policy. By using our Services following these changes,
                          you accept the revised Privacy Policy. If you don’t
                          agree to these changes, you may contact us to close
                          your account. In the event of conflict or
                          inconsistency between the Privacy Policy, and the
                          Terms, the Privacy Policy will take precedence.
                        </span>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default PrivacyComponent;
