import React from 'react';
import OwnerHeroSection from '../OwnerComponent/OwnerHeroSection';
import PopularCategories from '../OwnerComponent/PopularCategories';
import Accordion from '../common/Accordian';
import PopularListing from '../OwnerComponent/PopularListing';
import TestimonialComponent from '../OwnerComponent/Testimonial';
import TopRecommend from '../OwnerComponent/TopRecommendedDestinations';
import ListingCard from '../common/CreateListingCard';
import { ApplicationString } from '../../Constants/applicationString';
import { InternalRoute } from '../../Utils/internalRoutes';
import { useNavigate } from 'react-router-dom';
const OwnerComponent: React.FC = () => {
  const OwnerlistingCard = ApplicationString.OwnerlistingCard;
  const Navigate = useNavigate();
  const defaultFaqs = ApplicationString.accordion.accordionData;
  return (
    <React.Fragment>
      <OwnerHeroSection />
      <PopularCategories />
      <PopularListing />
      <TopRecommend />
      <TestimonialComponent />
      <Accordion
        darkTheme=""
        accordionData={defaultFaqs}
        title={ApplicationString.accordion.title}
      />
      <ListingCard
        dataTestId={'OwnerListingButton'}
        backgroundImage={OwnerlistingCard.backgroundImage}
        title={OwnerlistingCard.title}
        description={OwnerlistingCard.description}
        buttonText={OwnerlistingCard.buttonText}
        buttonOnClick={() => {
          Navigate(InternalRoute.Owner);
        }}
      />
    </React.Fragment>
  );
};

export default OwnerComponent;
