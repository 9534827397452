import axios, { AxiosInstance, AxiosResponse } from 'axios';
import {
  HTTP_ACCEPTED,
  HTTP_CREATED,
  HTTP_NO_CONTENT,
  HTTP_OK,
} from '../Constants/httpCode';
import { removeLocalStorageAccountInfo } from '../Utils/utils';
import { commonConfig } from '../Constants/commonConfig';

export const ApiRequest: AxiosInstance = axios.create({
  baseURL: commonConfig.ApiUrl,
  headers: {
    Accept: '*/*',
  },
});

ApiRequest.interceptors.request.use(
  async (config) => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  async (error) => {
    return Promise.reject(error);
  }
);

// let isRefreshTokenFetching = false;
// let failedApis: AxiosRequestConfig<unknown>[] = [];
/**
 * Interceptor for handling responses from all requests made using the ApiRequest instance.
 * This interceptor simply returns the response object without any modifications, acting as a pass-through.
 
 * @param {AxiosResponse} response - The response object from the Axios request.
 * @returns {AxiosResponse} The unmodified response object.
 */
ApiRequest.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  async () => {
    // error: AxiosError
    // const account = await getLocalStorageAccountInfo();
    try {
      // const originalRequest = error.config;
      // if (
      //   error.response?.status === HTTP_UNAUTHORIZED &&
      //   !originalRequest?.retry
      // ) {
      //   originalRequest.retry = true;
      //   if (!isRefreshTokenFetching) {
      //     failedApis.push(originalRequest);
      //     isRefreshTokenFetching = true;
      //     const response = await getNewJwtToken(account?.refreshToken);
      //     if (response.data.errorCode) {
      //       throw response;
      //     }
      //     if (response.data) {
      //       const { accessToken, refreshToken } = response.data;
      //       setLocalStorageAccountInfo({
      //         accessToken: accessToken,
      //         refreshToken: refreshToken,
      //         role: account?.role,
      //         user_id: account?.user_id,
      //       });
      //       if (failedApis.length > 0) {
      //         const failedApisCall = failedApis.map(
      //           async (api) => await ApiRequest(api)
      //         );
      //         await Promise.all(failedApisCall);
      //         isRefreshTokenFetching = false;
      //         failedApis = [];
      //       }
      //     }
      //   }
      //   console.log('failedApis', failedApis);
      //   // handle error: inform user, go to login, etc
      // } else if (
      //   error.response?.status === HTTP_FORBIDDEN &&
      //   !originalRequest?._retry
      // ) {
      //   // notificationService.error(HTTP_FORBIDDEN, error.response.data);
      // } else {
      //   failedApis.push(originalRequest);
      //   if (!isNullOrEmpty(error?.response?.data)) {
      //     // if (error?.response?.data?.errors?.length > 0) {
      //     //   error.response.data?.errors.map(() => {
      //     //     // err: AxiosError
      //     //     // notificationService.error('Error', err.message);
      //     //   });
      //     // } else {
      //     //   // notificationService.error(
      //     //   //   error.response?.data?.code,
      //     //   //   error.response.data.message
      //     //   // );
      //     // }
      //   } else {
      //     return await Promise.reject(error);
      //   }
      // }
    } catch (error) {
      removeLocalStorageAccountInfo();
      // window.location.href = '/login';
      // return Promise.reject(error);
    }
  }
);

export const isSuccessStatus = (status: number) =>
  [HTTP_OK, HTTP_CREATED, HTTP_NO_CONTENT, HTTP_ACCEPTED].includes(status);
